import { Controller } from "stimulus"
import MicroModal from "micromodal"
import debounce from "lodash/debounce"
import * as builder from "wellness/builder"
import * as shared from "controllers-shared"

const recordSearch = (query, articleId) => {
  const subdomain = shared.getMetaContentByName("subdomain")
  const body = {
    search_query: query,
    selected_article_id: articleId,
    subdomain: subdomain
  }
  fetch("/api4/wellness/record-search", {
    method: "post",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body)
  })
}

export default class extends Controller {
  static get targets() {
    return ["input", "content", "modal", "result", "status", "allResultsLink"]
  }

  highlightResult(resultIdx) {
    const totalResults = this.resultTargets.length;

    if (resultIdx >= totalResults) {
      resultIdx = 0;
    } else if (resultIdx < 0) {
      resultIdx = totalResults - 1;
    }

    this.resultIdx = resultIdx;

    this.resultTargets.forEach((el, i) => {
      el.classList.toggle("result--current", resultIdx === i);
    });

    const containerRect = this.contentTarget.getBoundingClientRect();
    const currentElement = this.resultTargets[resultIdx];
    const elementRect = currentElement.getBoundingClientRect();

    const containerCenter = containerRect.top + containerRect.height / 2;
    const elementCenter = elementRect.top + elementRect.height / 2;

    const offset = elementCenter - containerCenter;

    this.contentTarget.scrollBy({ top: offset });
  }

  next() {
    if (this.resultTargets.length > 0) {
      this.highlightResult(this.resultIdx + 1, "down")
    }
  }

  previous() {
    if (this.resultTargets.length > 0) {
      this.highlightResult(this.resultIdx - 1, "up")
    }
  }

  getSelected() {
    return this.resultTargets.find((r) => shared.hasClass(r, "result--current"))
  }

  cycleResults(e) {
    if (!this.modalTarget.classList.contains("is-open")) {
      return;
    }

    if (e.keyCode === 38) {
      // Arrow Up
      e.preventDefault()
      this.previous()
    } else if (e.keyCode === 40) {
      // Arrow Down or Tab
      e.preventDefault()
      this.next()
    } else if (e.keyCode === 13) {
      // Enter
      const selected = this.getSelected()
      if (selected) window.location = selected.firstChild.href
    }
  }

  buildResult(resource) {
    const result = document.createElement("li")
    const resultLink = document.createElement("a")
    resultLink.classList.add("result-link")
    resultLink.href = resource.url + "?with-search=" + encodeURIComponent(this.inputTarget.value)
    const resultCard = document.createElement("div")
    const className = "card--list"
    resultCard.classList.add(className, "card--" + resource["resource-type"])
    resultCard.appendChild(builder.buildTag(resource))
    resultCard.appendChild(builder.buildHeroImage(resource))
    resultCard.appendChild(builder.buildText(resource, className))
    resultLink.appendChild(resultCard)
    result.appendChild(resultLink)
    result.setAttribute("data-target", "search.result")
    return result
  }

  buildList(data) {
    const content = document.createElement("ul")
    content.classList.add("results-list")
    data.forEach((resource) => {
      content.appendChild(this.buildResult(resource))
    })
    return content
  }

  addContent(data) {
    this.contentTarget.appendChild(this.buildList(data))
  }

  resetSearchBar() {
    this.resultIdx = -1
    this.contentTarget.innerHTML = ""
    this.contentTarget.scrollTo(0, 0)
    this.modalTarget.classList.remove("no-results", "has-data")
  }

  queryServer(query) {
    return fetch("/api4/wellness/search?q=" + encodeURIComponent(query) + "&subdomain=" + shared.getMetaContentByName("subdomain"))
        .then((response) => response.json())
        .then((data) => {
          this.resetSearchBar()
          if (data.length > 0) {
            this.modalTarget.classList.add("has-data")
            this.addContent(data)
            this.allResultsLinkTarget.textContent = `Show all results for "${query}"`
          } else {
            this.modalTarget.classList.add("no-results")
            this.contentTarget.textContent = `No results for "${query}"`
          }
        })
  }

  onSearch() {
    let query = this.inputTarget.value
    let queryLength = query.length
    const modalClasses = this.modalTarget.classList

    if (queryLength >= 3) {
      if (Weglot && Weglot.initialized && Weglot.getCurrentLang() !== "en") {
        Weglot.search(query, (data) => {
          this.queryServer(data)
        })
      } else {
        this.queryServer(query)
      }
    } else if (queryLength >= 1) {
      modalClasses.remove("has-data")
      modalClasses.add("no-results")
      this.contentTarget.textContent = "Searching..."
    } else {
      this.resetSearchBar()
    }
  }

  connect() {
    MicroModal.init({
      disableScroll: false,
      onClose: () => {
        if (this.inputTarget.value.length > 0) {
          recordSearch(this.inputTarget.value, "") // Save search on clickaway (user searched but didn't choose an article)
        }
        this.resetSearchBar()
        this.inputTarget.value = ""
      }
    })
  }

  initialize() {
    this.onSearch = debounce(this.onSearch, 500)
  }
}