import { Controller } from "stimulus"
import * as shared from "controllers-shared"
import * as builder from "wellness/builder"
import * as util from "wellness/util"

const subdomain = shared.getMetaContentByName("subdomain")

export default class extends Controller {
  static get targets() {
    return ["resources", "loading", "loadmore"]
  }

  connect() {
    this.type = this.element.dataset.resourceType

    if (this.type === "collection") {
      this.collectionId = this.element.dataset.collectionId
    }

    if (this.type === "topics") {
      this.topicId = this.element.dataset.topicId
    }
  }

  nextPage() {
    const searchParams = new URLSearchParams(window.location.search)
    let pageParam = Number(searchParams.get("page"))
    return !pageParam ? 2 : pageParam + 1
  }

  getTopic() {
    var searchParams = new URLSearchParams(window.location.search)
    return searchParams.get("topic")
  }

  setPageParam(pageNumber) {
    util.setParam("page", pageNumber || 1)
  }

  getEndPoint(topic, page) {
    const type = this.type
    if (type === "topics") {
      return subdomain ? `/wellness/topic-resources/${this.topicId}?subdomain=${subdomain}&page=${page}` : `/wellness/topic-resources/${this.topicId}?page=${page}`
    } else if (type === "collection" && this.element.dataset.isCustom === "true") {
      return `/wellness/collections/${subdomain}/${this.collectionId}/resources?page=${page}`
    } else if (type === "collection") {
      return subdomain
        ? `/wellness/collections/${this.collectionId}/resources?subdomain=${subdomain}&page=${page}`
        : `/wellness/collections/${this.collectionId}/resources?page=${page}`
    } else if (type === "article" && topic && topic !== "all") {
      return subdomain ? `/wellness/articles/${topic}?subdomain=${subdomain}&page=${page}` : `/wellness/articles/${topic}?page=${page}`
    } else {
      return subdomain ? `/wellness/resources/${type}/page?subdomain=${subdomain}&page=${page}` : `/wellness/resources/${type}/page?page=${page}`
    }
  }

  getResources(page) {
    const topic = this.getTopic()
    const loadmore = this.loadmoreTarget
    const loading = this.loadingTarget
    builder.buildLoadingView2(loading)
    fetch(this.getEndPoint(topic, page))
      .then((response) => response.status === 200 && response.json())
      .then((data) => {
        const resources = data.resources
        data["last-page"] && builder.clearChildren(loadmore)
        return Object.keys(resources).map((i) => resources[i]) /* maps over map of resources */
      })
      .then((resources) => {
        builder.clearChildren(loading)
        resources.map((resource) => this.resourcesTarget.appendChild(builder.createCustomCard(resource)))
      })
  }

  onLoadMore() {
    let nextPage = this.nextPage()
    this.setPageParam(nextPage)
    this.getResources(nextPage)
  }
}
