import "./runtime"

import {Application} from "stimulus"
import whatInput from "what-input"
import articles_controller from "./wellness/controllers/articles_controller"
import auth_controller from "./wellness/controllers/auth_controller"
import body_controller from "./wellness/controllers/body_controller"
import branding_controller from "./wellness/controllers/branding_controller"
import cta_controller from "./wellness/controllers/cta_controller"
import cta_logo_controller from "./wellness/controllers/cta_logo_controller"
import footer_controller from "./wellness/controllers/footer_controller"
import full_color_controller from "./wellness/controllers/full-color_controller"
import pagination_controller from "./wellness/controllers/pagination_controller"
import popular_controller from "./wellness/controllers/popular_controller"
import reward_controller from "./wellness/controllers/reward_controller"
import reward_quiz_controller from "./wellness/controllers/reward-quiz_controller"
import search_controller from "./wellness/controllers/search_controller"
import sidebar_controller from "./wellness/controllers/sidebar_controller"
import social_controller from "./wellness/controllers/social_controller"
import sticky_header_controller from "./wellness/controllers/sticky-header_controller"
import svg_fetch_controller from "./wellness/controllers/svg_fetch_controller"
import toggle_controller from "./wellness/controllers/toggle_controller"
import truncate_controller from "./wellness/controllers/truncate_controller"
import nav_controller from "./wellness/controllers/nav_controller";

const application = Application.start()
application.register("articles", articles_controller)
application.register("auth", auth_controller)
application.register("body", body_controller)
application.register("branding", branding_controller)
application.register("cta", cta_controller)
application.register("cta-logo", cta_logo_controller)
application.register("footer", footer_controller)
application.register("full-color", full_color_controller)
application.register("pagination", pagination_controller)
application.register("popular", popular_controller)
application.register("reward", reward_controller)
application.register("reward-quiz", reward_quiz_controller)
application.register("sidebar", sidebar_controller)
application.register("social", social_controller)
application.register("search", search_controller)
application.register("sticky-header", sticky_header_controller)
application.register("svg-fetch", svg_fetch_controller)
application.register("toggle", toggle_controller)
application.register("truncate", truncate_controller)
application.register("nav", nav_controller)

